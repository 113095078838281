import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { CompanyDetailType, useCompanyTrendVisitsById } from '@/api/activity';
import Flare, { Area, Axis, Tooltip } from '@/components/Flare';
import { Panel } from '@/components/panels';
import { TAM_PRIMARY } from '@/constants/colors';
import { DateFormatMD, MonthDateYearFormat } from '@/constants/formats';
import { getUTCTime } from '@/helper/dateHelper';
import { adjustPositionByContainer } from '@/helper/highcharts-tooltip-extension';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  data: CompanyDetailType | null;
  startDate?: string;
  endDate?: string;
};

const CompanyTrendPanel = ({ data, startDate, endDate }: Props) => {
  const { companyTrendVisits, isLoading, error } = useCompanyTrendVisitsById(
    data?.id,
    startDate,
    endDate,
  );

  const seriesData = useMemo(() => {
    if (!companyTrendVisits) {
      return undefined;
    }

    return [
      {
        id: 'visits',
        zIndex: 1,
        name: t`Total visits`,
        data: companyTrendVisits,
      },
    ];
  }, [companyTrendVisits]);

  return (
    <Panel size="L" title={<Trans>Visit Trend</Trans>} style={{ height: 357 }} noPadding>
      <Flare
        data={seriesData}
        isLoading={isLoading}
        error={error}
        description={t`A chart showing total web traffic visits over time for the company.`}
        parseX={getUTCTime}
        colors={[TAM_PRIMARY]}
      >
        <Area x="sundayOfWeek" y="visits" position="overlap" />
        <Axis
          position="left"
          title={t`Visits`}
          labelFormat={(item) => numberFormat(item.value, { compact: true })}
          allowDecimals={false}
        />
        <Axis
          type="datetime"
          crosshair="line"
          position="bottom"
          labelFormat={(item) => dayjs(item.value).format(DateFormatMD)}
        />
        <Tooltip
          titleFormat={(point) => dayjs(point?.x).utc().format(MonthDateYearFormat)}
          rowValueFormat={(point) => numberFormat(point?.y)}
          rowSecondaryValueFormat={(point) =>
            point?.colorIndex !== 0
              ? `(${numberFormat((point?.y ?? 0) / point?.custom?.all, {
                  isPercent: true,
                  precision: 1,
                })})`
              : ''
          }
          positioner={(tooltip, labelWidth, labelHeight, point) => {
            return adjustPositionByContainer({
              x: point.plotX - labelWidth / 2,
              y: point.plotY + labelHeight / 2,
            });
          }}
        />
      </Flare>
    </Panel>
  );
};

export default CompanyTrendPanel;
