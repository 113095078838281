import { t } from '@lingui/core/macro';
import gql from 'graphql-tag';
import { useMemo } from 'react';

import { fetcherSol } from '@/api/sol-fetcher';
import { getVariablesFromTableParamsNew } from '@/api/util/getVariablesFromTableParams';
import { SolQueryParamsNew } from '@/components/InstantSearch';
import { useSolImmutableQuery, useSolQuery } from '@/hooks/useSolQuery';

import {
  NotificationCreateGQLResponse,
  NotificationCreateType,
  NotificationCreateVariablesType,
  NotificationDeleteGQLResponse,
  NotificationPageMeta,
  NotificationSubscriptionDataType,
  NotificationSubscriptionGQLResponse,
  NotificationSubscriptionRecurringDataType,
  NotificationUnsubscribeGQLResponse,
  NotificationUpdateGQLResponse,
  NotificationUpdateType,
  NotificationUpdateVariablesType,
  RegionsGQLResponse,
  RevenueRangesGQLResponse,
  SectorsGQLResponse,
} from './settingsType';

export const useSectors = ({ includeBlanks = false } = {}) => {
  const { data, isLoading, error } = useSolImmutableQuery<SectorsGQLResponse>({
    query: gql`
      query getSectors(
        $page: Pagination!
        $sort: [SortParamInput]!
        $filter: [FilterParamInput]!
        $queryId: String
      ) {
        sectors {
          get(page: $page, sort: $sort, filter: $filter, queryId: $queryId) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      page: {
        limit: 50,
        offset: 0,
      },
      sort: {
        direction: 'asc',
        field: 'name',
      },
      filter: [],
    },
  });

  const sectors = useMemo(() => {
    const sectors = data?.sectors.get.edges.map((edge) => edge.node);

    if (sectors && includeBlanks) {
      return [
        {
          id: '',
          name: t`(Blanks)`,
        },
        ...sectors,
      ];
    }
    return sectors;
  }, [data, includeBlanks]);

  return {
    sectors,
    isLoading,
    error,
  };
};

export const useRegions = ({ includeBlanks = false } = {}) => {
  const { data, isLoading, error } = useSolImmutableQuery<RegionsGQLResponse>({
    query: gql`
      query GetRegions(
        $page: Pagination!
        $sort: [SortParamInput]!
        $filter: [FilterParamInput]!
        $queryId: String
      ) {
        regions {
          get(page: $page, sort: $sort, filter: $filter, queryId: $queryId) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      page: {
        limit: 50,
        offset: 0,
      },
      sort: {
        direction: 'asc',
        field: 'name',
        nullsFirst: includeBlanks,
      },
      filter: [],
    },
  });

  const regions = useMemo(() => {
    const regions = data?.regions.get.edges
      .map((edge) => edge.node)
      .filter((region) => region.id !== 'regn_antarctica');

    if (regions && includeBlanks) {
      return [
        {
          id: '',
          name: t`(Blanks)`,
        },
        ...regions,
      ];
    }
    return regions;
  }, [data, includeBlanks]);

  return {
    regions,
    isLoading,
    error,
  };
};

export const useRevenueRanges = ({ includeBlanks = false } = {}) => {
  const { data, isLoading, error } = useSolImmutableQuery<RevenueRangesGQLResponse>({
    query: gql`
      query GetRevRanges(
        $page: Pagination!
        $sort: [SortParamInput]!
        $filter: [FilterParamInput]!
        $queryId: String
      ) {
        revRanges {
          get(page: $page, sort: $sort, filter: $filter, queryId: $queryId) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      page: {
        limit: 50,
        offset: 0,
      },
      sort: {
        direction: 'asc',
        field: 'name',
        nullsFirst: includeBlanks,
      },
      filter: [],
    },
  });

  const revRanges = useMemo(() => {
    const revRanges = data?.revRanges.get.edges.map((edge) => edge.node);

    if (revRanges && includeBlanks) {
      return [
        {
          id: '',
          name: t`(Blanks)`,
        },
        ...revRanges,
      ];
    }
    return revRanges;
  }, [data, includeBlanks]);

  return {
    revRanges,
    isLoading,
    error,
  };
};

export type PageMetaNotificationNameType =
  | 'attribution'
  | 'campaign'
  | 'campaigns'
  | 'channel'
  | 'channels'
  | 'company-activity'
  | 'dashboard'
  | 'pixel'
  | 'vendor'
  | 'vendors'
  | 'web-traffic';

export const useNotificationPageMeta = (
  key?: PageMetaNotificationNameType,
): NotificationPageMeta => {
  switch (key) {
    case 'company-activity':
      return {
        hasNoAudienceFilter: true,
      };
    case 'dashboard':
      return {
        hasNoAudienceFilter: true,
      };
    case 'web-traffic':
      return {
        kpiMetrics: [
          {
            value: 'web-traffic-total-visits',
            label: t`Total Visits`,
            allowsAudience: false,
          },
          {
            value: 'web-traffic-audience-visits',
            label: t`Audience Visits`,
            allowsAudience: true,
          },
          {
            value: 'web-traffic-engaged-companies',
            label: t`Engaged Companies`,
            allowsAudience: true,
          },
          {
            value: 'web-traffic-pipeline-influenced',
            label: t`Pipeline Influence`,
            allowsAudience: true,
          },
          {
            value: 'web-traffic-closed-won-influenced',
            label: t`Closed-Won Influence`,
            allowsAudience: true,
          },
        ],
      };
    case 'attribution':
      return {
        kpiMetrics: [
          { value: 'attribution-pipeline', label: t`Pipeline`, allowsAudience: false },
          { value: 'attribution-closed-won', label: t`Closed-Won`, allowsAudience: false },
          { value: 'attribution-visits-per-opp', label: t`Visits per Opp`, allowsAudience: false },
          {
            value: 'attribution-non-digital-events',
            label: t`Non-Digital Events per Opp`,
            allowsAudience: false,
          },
          {
            value: 'attribution-averagedeal-cycle',
            label: t`Average Deal Cycle Length`,
            allowsAudience: false,
          },
        ],
      };
    case 'channels':
      return {
        kpiMetrics: [
          { value: 'channels-total-visits', label: t`Total Visits`, allowsAudience: false },
          { value: 'channels-total-spend', label: t`Total Spend`, allowsAudience: false },
          {
            value: 'channels-spend-per-visit',
            label: t`Audience Spend Per Visit`,
            allowsAudience: false,
          },
          {
            value: 'channels-target-visits',
            label: t`Audience Visits`,
            allowsAudience: false,
          },
          {
            value: 'channels-target-spend-per-visit',
            label: t`Spend Per Visit`,
            allowsAudience: false,
          },
        ],
      };
    case 'channel':
      return {
        kpiMetrics: [
          { value: 'channel-total-visits', label: t`Total Visits`, allowsAudience: false },
          { value: 'channel-total-spend', label: t`Total Spend`, allowsAudience: false },
          {
            value: 'channel-spend-per-visit',
            label: t`Audience Spend Per Visit`,
            allowsAudience: false,
          },
          {
            value: 'channel-target-visits',
            label: t`Audience Visits`,
            allowsAudience: false,
          },
          {
            value: 'channel-target-spend-per-visit',
            label: t`Audience Spend Per Visit`,
            allowsAudience: false,
          },
        ],
      };
    case 'vendors':
      return {
        kpiMetrics: [
          { value: 'vendors-total-visits', label: t`Total Visits`, allowsAudience: false },
          {
            value: 'vendors-target-visits',
            label: t`Audience Visits`,
            allowsAudience: true,
          },
          {
            value: 'vendors-engaged-companies',
            label: t`Audience Engaged Companies`,
            allowsAudience: true,
          },
          {
            value: 'vendors-pipeline-influenced',
            label: t`Audience Pipeline Influence`,
            allowsAudience: true,
          },
          {
            value: 'vendors-closed-won-influenced',
            label: t`Audience Closed-Won Influence`,
            allowsAudience: true,
          },
        ],
      };
    case 'vendor':
      return {
        kpiMetrics: [
          { value: 'vendor-total-visits', label: t`Total Visits`, allowsAudience: false },
          {
            value: 'vendor-target-visits',
            label: t`Audience Visits`,
            allowsAudience: true,
          },
          {
            value: 'vendor-engaged-companies',
            label: t`Audience Engaged Companies`,
            allowsAudience: true,
          },
          {
            value: 'vendor-pipeline-influenced',
            label: t`Audience Pipeline Influence`,
            allowsAudience: true,
          },
          {
            value: 'vendor-closed-won-influenced',
            label: t`Audience Closed-Won Influence`,
            allowsAudience: true,
          },
        ],
      };
    case 'campaigns':
      return {
        kpiMetrics: [
          { value: 'campaigns-total-visits', label: t`Total Visits`, allowsAudience: false },
          {
            value: 'campaigns-target-spend',
            label: t`Audience Visits`,
            allowsAudience: true,
          },
          {
            value: 'campaigns-engaged-companies',
            label: t`Audience Engaged Companies`,
            allowsAudience: true,
          },
          {
            value: 'campaigns-pipeline-influenced',
            label: t`Audience Pipeline Influence`,
            allowsAudience: true,
          },
          {
            value: 'campaigns-closed-won-influenced',
            label: t`Audience Closed-Won Influence`,
            allowsAudience: true,
          },
        ],
      };
    case 'campaign':
      return {
        kpiMetrics: [
          { value: 'campaign-total-visits', label: t`Total Visits`, allowsAudience: false },
          {
            value: 'campaign-visit-efficiency',
            label: t`Audience Visit Efficiency`,
            allowsAudience: true,
          },
          {
            value: 'campaign-engaged-companies',
            label: t`Audience Engaged Companies`,
            allowsAudience: true,
          },
          {
            value: 'campaign-roms-pipeline-influenced',
            label: t`Audience ROMS Pipeline Influence`,
            allowsAudience: true,
          },
          {
            value: 'campaign-closed-won-influenced',
            label: t`Audience Closed-Won Influence`,
            allowsAudience: true,
          },
        ],
      };
    case 'pixel':
      return {
        kpiMetrics: [
          { value: 'pixel-total-impressions', label: t`Total Impressions`, allowsAudience: false },
          {
            value: 'pixel-efficiency',
            label: t`Audience Pixel Efficiency`,
            allowsAudience: false,
          },
          { value: 'pixel-reached-companies', label: t`Reached Companies`, allowsAudience: false },
          { value: 'pixel-engaged-companies', label: t`Engaged Companies`, allowsAudience: false },
          {
            value: 'pixel-pipeline-influenced',
            label: t`Pipeline Influence`,
            allowsAudience: false,
          },
          {
            value: 'pixel-closed-won-influenced',
            label: t`Closed-Won Influence`,
            allowsAudience: false,
          },
        ],
      };
    default:
      throw new Error(`Unknown page key: ${key}`);
  }
};

export const useNotificationSubscriptionsList = (tableParams?: SolQueryParamsNew) => {
  const variables = getVariablesFromTableParamsNew(tableParams);
  const { data, isLoading, error, mutate } = useSolQuery<NotificationSubscriptionGQLResponse>({
    query:
      tableParams &&
      gql`
        query MyNotifications(
          $page: Pagination!
          $sort: [SortParamInput]!
          $filter: [FilterParamInput]!
        ) {
          notifications {
            mine(page: $page, sort: $sort, filter: $filter) {
              edges {
                node {
                  id
                  type
                  name
                  achieved
                  additionalRecipients {
                    email
                    id
                    name
                  }
                  createdAt
                  createdBy {
                    email
                    id
                    name
                  }
                  goal
                  goalStartDate
                  repeatsWeeks
                  reportingWindowDays
                  metric
                  lastRunDate
                  lastGoalValue
                  kpiId
                  sunday
                  monday
                  tuesday
                  wednesday
                  thursday
                  friday
                  saturday
                }
              }
            }
          }
        }
      `,
    variables,
  });

  const subscriptions = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return data.notifications.mine.edges.map((edge) => {
      const { node } = edge;
      node.additionalRecipients = node.additionalRecipients ? node.additionalRecipients : [];

      const newNode: NotificationSubscriptionDataType = {
        // TODO: handle goal notifications
        ...(node as NotificationSubscriptionRecurringDataType),
        allRecipients: [node.createdBy, ...node.additionalRecipients],
        deliveryDays: [],
      };

      newNode.type = newNode.type ?? 'recurring';

      if (newNode.type === 'recurring' && newNode.deliveryDays) {
        newNode.repeatsWeeks = `${newNode.repeatsWeeks}`;
        newNode.reportingWindowDays = `${newNode.reportingWindowDays}`;

        if (newNode.sunday) {
          newNode.deliveryDays.push('0');
        }
        if (newNode.monday) {
          newNode.deliveryDays.push('1');
        }
        if (newNode.tuesday) {
          newNode.deliveryDays.push('2');
        }
        if (newNode.wednesday) {
          newNode.deliveryDays.push('3');
        }
        if (newNode.thursday) {
          newNode.deliveryDays.push('4');
        }
        if (newNode.friday) {
          newNode.deliveryDays.push('5');
        }
        if (newNode.saturday) {
          newNode.deliveryDays.push('6');
        }
      }

      return newNode;
    });
  }, [data]);

  return {
    subscriptions,
    totalResults: data?.notifications.mine.totalEdges,
    isLoading,
    error,
    mutate,
  };
};

export const mutateNotificationCreate = async (notification: NotificationCreateType) => {
  const response = await fetcherSol<NotificationCreateVariablesType, NotificationCreateGQLResponse>(
    {
      query: gql`
        mutation CreateNotification($notification: CreateNotificationInput!) {
          notifications {
            createNotification(notification: $notification) {
              id
            }
          }
        }
      `,
      variables: {
        notification,
      },
    },
  );

  return response.notifications.createNotification.id;
};

export const mutateNotificationUpdate = async (notification: NotificationUpdateType) => {
  const response = await fetcherSol<NotificationUpdateVariablesType, NotificationUpdateGQLResponse>(
    {
      query: gql`
        mutation UpdateNotification($notification: UpdateNotificationInput!) {
          notifications {
            update(notification: $notification) {
              id
            }
          }
        }
      `,
      variables: {
        notification,
      },
    },
  );

  return response.notifications.update.id;
};

export const mutateNotificationUnsubscribe = async (notificationId: string) => {
  const response = await fetcherSol<{ notificationId: string }, NotificationUnsubscribeGQLResponse>(
    {
      query: gql`
        mutation UnsubscribeNotification($notificationId: String!) {
          notifications {
            unsubscribe(id: $notificationId) {
              success
            }
          }
        }
      `,
      variables: {
        notificationId,
      },
    },
  );

  return response.notifications.unsubscribe.success;
};

export const mutateNotificationDelete = async (notificationId: string) => {
  const response = await fetcherSol<{ notificationId: string }, NotificationDeleteGQLResponse>({
    query: gql`
      mutation DeleteNotification($notificationId: String!) {
        notifications {
          delete(id: $notificationId) {
            success
          }
        }
      }
    `,
    variables: {
      notificationId,
    },
  });

  return response.notifications.delete.success;
};
