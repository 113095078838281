import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useVendorWeeklyVisits } from '@/api/vendor';
import Flare, { Area, Axis, FlareSeriesOptions, Legend, Notes, Tooltip } from '@/components/Flare';
import { usePageFilterContext } from '@/components/page';
import { NotesPanel } from '@/components/panels';
import { ALL_PRIMARY, AUDIENCE_PRIMARY, TAM_PRIMARY } from '@/constants/colors';
import { DateFormatMD, MonthDateYearFormat } from '@/constants/formats';
import { getUTCTime } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  vendorId?: string;
  isLoading?: boolean;
};

const VendorWeeklyVisitsChart = ({ vendorId, isLoading: isVendorLoading }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { startDate, endDate, audience } = pageFilters;
  const {
    vendorWeeklyVisits: data,
    isLoading: isVendorWeeklyVisitsLoading,
    error,
  } = useVendorWeeklyVisits(vendorId, startDate, endDate, audience?.id);

  const isLoading = isVendorLoading || isVendorWeeklyVisitsLoading;

  const sanitizedData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (data == null) {
      return undefined;
    }

    const allSeries = {
      id: 'all',
      zIndex: 1,
      name: t`ALL`,
      data: data.map((d) => ({ amount: d.visits.all, date: d.sundayOfWeek })),
    };
    const tamSeries = {
      id: 'tam',
      zIndex: 2,
      name: t`TAM`,
      data: data.map((d) => ({
        amount: d.visits.tam,
        date: d.sundayOfWeek,
        custom: { all: d.visits.all },
      })),
    };

    if (audience?.id) {
      const audienceSeries = {
        id: 'audience',
        zIndex: 3,
        name: audience?.name,
        data: data.map((d) => ({
          amount: d.visits.audience,
          date: d.sundayOfWeek,
          custom: { all: d.visits.all },
        })),
      };

      return [allSeries, tamSeries, audienceSeries];
    }

    return [allSeries, tamSeries];
  }, [data, audience]);

  return (
    <NotesPanel
      startDate={startDate}
      endDate={endDate}
      size="L"
      title={<Trans>Total Visits</Trans>}
      noPadding
      style={{ height: 578 }}
    >
      <Flare
        data={sanitizedData}
        description={t`A trend showing impressions generated by an optional target Audience, Total Addressable Market (TAM), and everything else.`}
        parseX={getUTCTime}
        colors={
          audience?.id ? [ALL_PRIMARY, TAM_PRIMARY, AUDIENCE_PRIMARY] : [ALL_PRIMARY, TAM_PRIMARY]
        }
        isLoading={isLoading}
        error={error}
      >
        <Legend reversed />
        <Area x="date" y="amount" position="overlap" />
        <Axis position="left" title={t`Visits`} labelFormat={(item) => numberFormat(item.value)} />
        <Axis
          type="datetime"
          crosshair="line"
          position="bottom"
          labelFormat={(item) => dayjs(item.value).format(DateFormatMD)}
        />
        <Tooltip
          titleFormat={(point) => dayjs(point?.x).format(MonthDateYearFormat)}
          rowValueFormat={(point) => numberFormat(point?.y)}
          rowSecondaryValueFormat={(point) =>
            point?.colorIndex !== 0
              ? `(${numberFormat((point?.y ?? 0) / point?.custom?.all, {
                  isPercent: true,
                  precision: 1,
                })})`
              : ''
          }
          shared
        />
        <Notes />
      </Flare>
    </NotesPanel>
  );
};

export default VendorWeeklyVisitsChart;
