import dayjs from 'dayjs';

import { InstanceShortDataType, UserInstanceType } from '@/api/organization';
import { DateRangeType } from '@/components/page';
import { DateBEFormat } from '@/constants';

const WEB_STORAGE = {
  LAST_VISITED_ORG: `channel99-last_visited_org-${import.meta.env.VITE_ENVIRONMENT}`,
  FOREIGN_SUPERUSER_INSTANCE: `channel99-foreign_superuser_instance-${
    import.meta.env.VITE_ENVIRONMENT
  }`,
  DEFAULT_DATE_RANGE: `channel99-default-date-range-${import.meta.env.VITE_ENVIRONMENT}`,
  TABLE_COLUMNS_STATE: (tableKey: string) =>
    `channel99-table-columns-state-${tableKey}-${import.meta.env.VITE_ENVIRONMENT}`,
};

export const getLastVisitedOrgSlug = () => localStorage.getItem(WEB_STORAGE.LAST_VISITED_ORG);
export const setLastVisitedOrgSlug = (orgSlug: string) =>
  localStorage.setItem(WEB_STORAGE.LAST_VISITED_ORG, orgSlug);
export const clearLastVisitedOrgSlug = () => localStorage.removeItem(WEB_STORAGE.LAST_VISITED_ORG);

export const getForeignSuperuserInstance = () => {
  const instanceStr = localStorage.getItem(WEB_STORAGE.FOREIGN_SUPERUSER_INSTANCE);

  if (instanceStr == null) {
    return null;
  }

  try {
    return JSON.parse(instanceStr) as UserInstanceType;
  } catch {
    // oops! Old format, just return the id
    // Should be able to delete this after a few weeks
    return {
      id: '',
      name: instanceStr?.replace(/^inst:/, ''),
      slug: instanceStr?.replace(/^inst:/, 'inst~'),
      instanceId: instanceStr,
    } as UserInstanceType;
  }
};
export const setForeignSuperuserInstance = (instance: InstanceShortDataType) => {
  const instanceObj = {
    id: '',
    name: instance.name,
    slug: instance.id.replace(/^inst:/, 'inst~'),
    instanceId: instance.id,
  } as UserInstanceType;
  localStorage.setItem(WEB_STORAGE.FOREIGN_SUPERUSER_INSTANCE, JSON.stringify(instanceObj));
};
export const clearForeignSuperuserInstance = () =>
  localStorage.removeItem(WEB_STORAGE.FOREIGN_SUPERUSER_INSTANCE);

const getDateRangeFromURL = () => {
  const url = new URL(window.location.href);
  const startDate = url.searchParams.get('startDate');
  const endDate = url.searchParams.get('endDate');
  if (startDate) {
    const dateRange = { startDate, endDate: endDate ?? dayjs().format(DateBEFormat) };
    sessionStorage.setItem(WEB_STORAGE.DEFAULT_DATE_RANGE, JSON.stringify(dateRange));

    // remove date range from url
    url.searchParams.delete('startDate');
    url.searchParams.delete('endDate');
    window.history.replaceState(null, '', url);

    return dateRange;
  }
  return null;
};

const getDateRangeFromSessionStorage = () => {
  const dateRange = sessionStorage.getItem(WEB_STORAGE.DEFAULT_DATE_RANGE);
  if (dateRange) {
    // TODO: drop `start_date` and `end_date` after a while. We'll need to make sure they are out
    // session storage first. If it's been a few weeks since this was push to production, it should
    // be removable.
    const parsedDateRange: DateRangeType & {
      start_date?: string;
      end_date?: string;
    } = JSON.parse(dateRange);

    // if the old snakecase `start_date` format is still in storage, reset it with camelcase format
    // TODO: Remove this `if` statement once in prod for a few weeks.
    if (parsedDateRange.start_date || parsedDateRange.end_date) {
      const reformattedDateRange = {
        startDate: parsedDateRange.start_date || parsedDateRange.startDate,
        endDate: parsedDateRange.end_date || parsedDateRange.endDate,
      };
      sessionStorage.setItem(WEB_STORAGE.DEFAULT_DATE_RANGE, JSON.stringify(reformattedDateRange));
      return reformattedDateRange;
    }

    return parsedDateRange;
  }
  return null;
};

export const getDefaultDateRange = () => {
  return getDateRangeFromURL() ?? getDateRangeFromSessionStorage();
};
export const setDefaultDateRange = (dateRange: { startDate: string; endDate: string }) =>
  sessionStorage.setItem(WEB_STORAGE.DEFAULT_DATE_RANGE, JSON.stringify(dateRange));
export const removeDefaultDateRange = () =>
  sessionStorage.removeItem(WEB_STORAGE.DEFAULT_DATE_RANGE);

export const getVisibleTableColumnsState = (tableKey: string) => {
  const columnStateStr = localStorage.getItem(WEB_STORAGE.TABLE_COLUMNS_STATE(tableKey));
  if (columnStateStr == null) {
    return null;
  }

  try {
    return JSON.parse(columnStateStr) as { sortedColumns: string[]; hiddenColumns: string[] };
  } catch {
    return null;
  }
};

export const setVisibleTableColumnsState = (
  tableKey: string,
  columnState: { sortedColumns: string[]; hiddenColumns: string[] },
) => {
  localStorage.setItem(WEB_STORAGE.TABLE_COLUMNS_STATE(tableKey), JSON.stringify(columnState));
};

export const clearSessionStorage = () => sessionStorage.clear();
