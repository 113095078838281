import { t } from '@lingui/core/macro';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';

import { OpportunityStatusType } from '@/api/opportunity';
import AttributionKPIMetrics from '@/app/attribution/AttributionKPIMetrics';
import AttributionOfflineBreakdownTable from '@/app/attribution/AttributionOfflineBreakdownTable';
import AttributionOnlineBreakdownTableContainer from '@/app/attribution/AttributionOnlineBreakdownTableContainer';
import AttributionTrendsChart from '@/app/attribution/AttributionTrendsChart';
import { OpportunityTypeSelect } from '@/app/attribution/OpportunityTypeSelect';
import { Flex } from '@/components/Flex';
import { InstantSearchSortOrder, QueryState } from '@/components/InstantSearch';
import InstantSearch from '@/components/InstantSearch/InstantSearch';
import {
  Page,
  PageAudienceFilter,
  PageDateRangeFilter,
  PageFilterProvider,
} from '@/components/page';
import ShareMenu from '@/features/ShareMenu';

const ONLINE_DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'visits',
  sortOrder: InstantSearchSortOrder.DESC,
  size: 10,
};

const OFFLINE_DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'visits',
  sortOrder: InstantSearchSortOrder.DESC,
  size: 10,
};

const AttributionOverview = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const oppType = searchParams.get('oppType');

  useEffect(() => {
    if (oppType !== 'pipeline' && oppType !== 'closedWon') {
      setSearchParams({ oppType: 'pipeline' }, { replace: true });
    }
  }, []);

  const handleOppTypeChange = (value: OpportunityStatusType) => {
    setSearchParams({ oppType: value });
  };

  if (oppType !== 'pipeline' && oppType != 'closedWon') {
    return null;
  }

  return (
    <Page title={t`Analytics`} pageName={t`Attribution`}>
      <Flex gap="large" vertical fullWidth>
        <PageFilterProvider>
          <Flex justify="space-between">
            <PageDateRangeFilter />
            <Flex gap="small">
              <OpportunityTypeSelect value={oppType} onChange={handleOppTypeChange} />
              <PageAudienceFilter />
              <ShareMenu notificationPageKey="attribution" />
            </Flex>
          </Flex>
          <AttributionKPIMetrics />
          <AttributionTrendsChart oppType={oppType} />
          <InstantSearch defaultQueryState={ONLINE_DEFAULT_QUERY_STATE} urlSync={false}>
            <AttributionOnlineBreakdownTableContainer oppType={oppType} />
          </InstantSearch>
          <InstantSearch defaultQueryState={OFFLINE_DEFAULT_QUERY_STATE} urlSync={false}>
            <AttributionOfflineBreakdownTable oppType={oppType} />
          </InstantSearch>
        </PageFilterProvider>
      </Flex>
    </Page>
  );
};

export default AttributionOverview;
