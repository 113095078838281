import { t } from '@lingui/core/macro';
import { useMemo, useState } from 'react';

import { TRAFFIC_TYPES } from '@/api/common';
import { OfflineActivityDataType, useOfflineActivity } from '@/api/vendor';
import OfflineActivityTable from '@/app/vendors/OfflineActivityTable';
import { useInstantSearchState } from '@/components/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { ColumnsType, FilterDropdown } from '@/components/Table';
import { RangeFilter } from '@/components/filters';
import { usePageFilterContext } from '@/components/page';
import { Text } from '@/components/typography';
import { AUDIENCE_PRIMARY, TAM_PRIMARY, VISITS_PRIMARY } from '@/constants';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';
import { VisitsTableBarCell } from '@/features/VisitsTableBarCell';
import didClickOnAntTooltip from '@/helper/didClickOnAntTooltip';
import { numberFormat } from '@/helper/numberFormatter';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { TableState } from '@/providers/TableState';
import { ROUTES } from '@/router';

const OfflineActivityTableContainer = () => {
  const hasAttributionFlag = useFeatureFlag(FEATURE_FLAG.attribution);
  const {
    pageFilters: { startDate, endDate, audience },
  } = usePageFilterContext();
  const { queryState } = useInstantSearchState();
  const [disabledSeries, setDisabledSeries] = useState<TRAFFIC_TYPES[]>([]);

  const { offlineActivity, isLoading, error } = useOfflineActivity(
    getSolQueryParamsNewFromQueryState(queryState),
    startDate,
    endDate,
    audience?.id,
  );

  const [hoveredRow, setHoveredRow] = useState<OfflineActivityDataType | null>(null);
  const [stickyRow, setStickyRow] = useState<{
    element: HTMLElement;
    record: OfflineActivityDataType;
  } | null>(null);

  useOnClickOutside(stickyRow?.element, (event) => {
    if (!didClickOnAntTooltip(event.target)) {
      setStickyRow(null);
    }
  });

  const maxVisits = useMemo(
    () =>
      offlineActivity?.reduce((max, d) => {
        let total = 0;

        if (audience?.id) {
          if (!disabledSeries.includes(TRAFFIC_TYPES.AUDIENCE)) {
            total += d.targetVisits;
          }
        } else {
          if (!disabledSeries.includes(TRAFFIC_TYPES.TAM)) {
            total += d.targetVisits;
          }
        }

        if (!disabledSeries.includes(TRAFFIC_TYPES.OTHER)) {
          total += d.otherVisits;
        }

        return Math.max(total, max);
      }, 0),
    [offlineActivity, disabledSeries],
  );

  const sortFields = ROUTES.vendors.searchParams['v.sortBy'].values;

  const offlineActivityMetrics: ColumnsType<OfflineActivityDataType> = useMemo(
    () => [
      {
        title: t`Spend`,
        key: 'spend',
        width: 110,
        align: 'right',
        sorter: sortFields.includes('spend'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Occurrences/Events`,
        key: 'visits',
        width: 300,
        align: 'left',
        sorter: sortFields.includes('visits'),
        defaultSortOrder: 'descend',
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (_, field) => (
          <VisitsTableBarCell
            data={[
              {
                type: audience?.id ? TRAFFIC_TYPES.AUDIENCE : TRAFFIC_TYPES.TAM,
                label: audience?.name ?? t`TAM`,
                value: field.targetVisits,
                color: audience?.id ? AUDIENCE_PRIMARY : TAM_PRIMARY,
                isDisabled: audience?.id
                  ? disabledSeries.includes(TRAFFIC_TYPES.AUDIENCE)
                  : disabledSeries.includes(TRAFFIC_TYPES.TAM),
              },
              {
                type: TRAFFIC_TYPES.OTHER,
                label: t`Other Companies`,
                value: field.otherVisits,
                color: VISITS_PRIMARY,
                isDisabled: disabledSeries.includes(TRAFFIC_TYPES.OTHER),
              },
            ]}
            maxVisits={maxVisits}
            isOpaque={
              (stickyRow == null && hoveredRow == null) ||
              stickyRow?.record === field ||
              hoveredRow === field
            }
            isTooltipOpen={hoveredRow === field || stickyRow?.record === field}
            isTooltipSticky={stickyRow?.record === field}
            tooltipTitle={'TODO'}
            onStickyTooltipClose={() => setStickyRow(null)}
          />
        ),
        onCell: (record) => ({
          style: { cursor: 'pointer' },
          onMouseEnter: () => setHoveredRow(record),
          onMouseLeave: () => setHoveredRow(null),
          onClick: (e) => {
            if (didClickOnAntTooltip(e.target)) {
              return;
            }

            // clicking on the same row again will close the tooltip
            if (stickyRow?.record === record) {
              setStickyRow(null);
            } else {
              setStickyRow({ element: e.currentTarget as HTMLElement, record });
            }
          },
        }),
      },
      {
        title: t`View-Through Visits`,
        key: 'viewThroughVisits',
        align: 'right',
        sorter: sortFields.includes('viewThroughVisits'),
        width: 140,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (_, field) => numberFormat(field.viewThroughVisits),
      },
      {
        title: t`Engaged Companies`,
        key: 'engagedCompanies',
        width: 115,
        align: 'right',
        sorter: sortFields.includes('engagedCompanies'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (_, field) =>
          +field.engagedCompanies > 0 ? (
            <Text variant="caption1">{numberFormat(field.engagedCompanies)}</Text>
          ) : (
            0
          ),
      },
      {
        title: t`Pipeline Influence`,
        key: 'pipelineInfluenced',
        width: 120,
        align: 'right',
        sorter: sortFields.includes('pipelineInfluenced'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Closed-Won Influence`,
        key: 'closedWonInfluenced',
        width: 120,
        align: 'right',
        sorter: sortFields.includes('closedWonInfluenced'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`First-Touch Pipeline`,
        key: 'pipelineFirstTouchAttribution',
        width: 120,
        align: 'right',
        hidden: !hasAttributionFlag,
        sorter: sortFields.includes('pipelineFirstTouchAttribution'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`First-Touch Closed-Won`,
        key: 'closedWonFirstTouchAttribution',
        width: 120,
        align: 'right',
        hidden: !hasAttributionFlag,
        sorter: sortFields.includes('closedWonFirstTouchAttribution'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Last-Touch Pipeline`,
        key: 'pipelineLastTouchAttribution',
        width: 120,
        align: 'right',
        hidden: !hasAttributionFlag,
        sorter: sortFields.includes('pipelineLastTouchAttribution'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Linear Pipeline`,
        key: 'pipelineLinearAttribution',
        width: 120,
        align: 'right',
        hidden: !hasAttributionFlag,
        sorter: sortFields.includes('pipelineLinearAttribution'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Linear Closed-Won`,
        key: 'closedWonLinearAttribution',
        width: 120,
        align: 'right',
        hidden: !hasAttributionFlag,
        sorter: sortFields.includes('closedWonLinearAttribution'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
    ],
    [maxVisits, audience, hoveredRow, stickyRow, disabledSeries, hasAttributionFlag],
  );

  return (
    <TableState tableKey="offlineActivity" columns={offlineActivityMetrics}>
      <OfflineActivityTable
        data={offlineActivity}
        isLoading={isLoading}
        error={error}
        onDisabledSeriesChange={setDisabledSeries}
      />
    </TableState>
  );
};

export default OfflineActivityTableContainer;
