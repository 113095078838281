import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo } from 'react';

import type { GenericActivityDataType } from '@/api/attribution';
import { OpportunityStatusType } from '@/api/opportunity';
import CampaignFilter from '@/app/campaigns/CampaignFilter';
import VendorFilter from '@/app/vendors/VendorFilter';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType, FilterDropdown } from '@/components/Table';
import { RangeFilter } from '@/components/filters';
import { Link } from '@/components/typography';
import type SolAPIError from '@/error/SolAPIError';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

type Props = {
  oppType: OpportunityStatusType;
  breakdownObject: 'vendor' | 'campaign';
  data: GenericActivityDataType[] | undefined;
  totalResults: number | undefined;
  isLoading: boolean;
  error: SolAPIError | null;
};

const AttributionOnlineBreakdownTable = ({
  oppType,
  breakdownObject,
  data,
  totalResults,
  isLoading,
  error,
}: Props) => {
  const columns: ColumnsType<GenericActivityDataType> = useMemo(
    () => [
      {
        title: breakdownObject === 'vendor' ? t`Vendor` : t`Campaign`,
        key: breakdownObject === 'vendor' ? 'vendorId' : 'campaignId',
        width: 240,
        fixed: 'left',
        sorter: true,
        filterDropdown:
          breakdownObject === 'vendor'
            ? (props) => (
                <FilterDropdown {...props}>
                  <VendorFilter />
                </FilterDropdown>
              )
            : (props) => (
                <FilterDropdown {...props}>
                  <CampaignFilter />
                </FilterDropdown>
              ),
        render: (_, field) => (
          <Link
            variant="caption1"
            to={
              breakdownObject === 'vendor'
                ? ROUTES.vendorById(field.id)
                : ROUTES.campaignById(field.id)
            }
          >
            {field.name}
          </Link>
        ),
      },
      {
        title: t`Visits`,
        key: 'visits',
        width: 100,
        align: 'right',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text, field) => numberFormat(field.allVisits),
      },
      {
        title: t`Pipeline Influence`,
        key: 'pipelineInfluenced',
        width: 120,
        align: 'right',
        hidden: oppType === 'closedWon',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Closed-Won Influence`,
        key: 'closedWonInfluenced',
        width: 120,
        align: 'right',
        hidden: oppType === 'pipeline',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`First-Touch Attribution`,
        key:
          oppType === 'pipeline'
            ? 'pipelineFirstTouchAttribution'
            : 'closedWonFirstTouchAttribution',
        width: 120,
        align: 'right',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Last-Touch Attribution`,
        key: 'pipelineLastTouchAttribution',
        width: 120,
        align: 'right',
        hidden: oppType === 'closedWon',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Linear Attribution`,
        key: oppType === 'pipeline' ? 'pipelineLinearAttribution' : 'closedWonLinearAttribution',
        width: 120,
        align: 'right',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
    ],
    [breakdownObject, oppType],
  );

  return (
    <InstantSearchTable<GenericActivityDataType>
      data-testid="attribution-table"
      columns={columns}
      dataSource={data}
      totalResults={totalResults}
      loading={isLoading}
      emptyMessage={
        breakdownObject === 'vendor' ? (
          <Trans>No vendors found</Trans>
        ) : (
          <Trans>No campaigns found</Trans>
        )
      }
      error={error}
      rowKey={(record) => record.id}
      sticky
      scroll={{ x: 700 }}
    />
  );
};

export default AttributionOnlineBreakdownTable;
