import { plural, t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { AudienceGroupedOptionType } from '@/api/audience';
import { usePixelReachEngagementTrend } from '@/api/pixel';
import Flare, { Area, Axis, Legend, Notes, Tooltip } from '@/components/Flare';
import { usePageFilterContext } from '@/components/page';
import { NotesPanel } from '@/components/panels';
import { AUDIENCE_PRIMARY, AUDIENCE_SECONDARY } from '@/constants/colors';
import { DateFormatMD, MonthDateYearFormat } from '@/constants/formats';
import { getUTCTime } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';
import { WebTrafficSearchParamType } from '@/router/searchParams/webTrafficSearchParams';

type Props = {
  pixelIds?: string[];
  audience?: AudienceGroupedOptionType;
  isLoading: boolean;
};

const PixelReachAndEngagementTrendChart = ({ pixelIds, audience, isLoading }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { startDate, endDate } = pageFilters;
  const audienceIds = audience?.audienceIds;

  const {
    pixelReachEngagementTrend,
    isLoading: isReachEngagementLoading,
    error,
  } = usePixelReachEngagementTrend(pixelIds, startDate, endDate, audienceIds);

  const seriesData = useMemo(() => {
    if (!pixelReachEngagementTrend) {
      return undefined;
    }

    return [
      {
        id: 'reach',
        zIndex: 1,
        name: t`Reach`,
        data: pixelReachEngagementTrend.map((d) => ({
          amount: d.reach / d.audienceSize,
          date: d.sundayOfWeek,
          custom: { count: d.reach },
        })),
      },
      {
        id: 'engagement',
        zIndex: 2,
        name: t`Engagement`,
        data: pixelReachEngagementTrend.map((d) => ({
          amount: d.engagement / d.audienceSize,
          date: d.sundayOfWeek,
          custom: { count: d.engagement },
        })),
      },
    ];
  }, [pixelReachEngagementTrend]);

  return (
    <NotesPanel
      startDate={startDate}
      endDate={endDate}
      size="L"
      title={<Trans>Reach and Engagement</Trans>}
      noPadding
      style={{ height: 579 }}
    >
      <Flare
        data={seriesData}
        description={t`A trend showing Reach and Engagement data for the pixel over time for the audience`}
        parseX={getUTCTime}
        colors={[AUDIENCE_PRIMARY, AUDIENCE_SECONDARY]}
        isLoading={isLoading || isReachEngagementLoading}
        error={error}
      >
        <Legend />
        <Area x="date" y="amount" fillOpacity={0.85} position="overlap" />
        <Axis
          position="left"
          title={t`Reach / Engagement %`}
          labelFormat={(item) => numberFormat(item.value, { isPercent: true })}
        />
        <Axis
          type="datetime"
          crosshair="line"
          position="bottom"
          labelFormat={(item) => dayjs(item.value).format(DateFormatMD)}
        />
        <Tooltip
          titleFormat={(point) => dayjs(point?.x).format(MonthDateYearFormat)}
          rowValueFormat={(point) =>
            plural(point?.custom?.count, { one: '# Company', other: '# Companies ' })
          }
          rowSecondaryValueFormat={(item) =>
            `(${numberFormat(item?.y, { isPercent: true, precision: 1 })})`
          }
          rowValueLink={(item) => {
            const defaultFilters: Partial<WebTrafficSearchParamType> = {
              'w.pixelIds': pixelIds,
              'w.audienceIds': audienceIds,
            };

            if (item?.colorIndex === 0) {
              return ROUTES.webTrafficActivityWithParams({
                ...defaultFilters,
                'w.metrics.impressions': '[1,]',
              });
            } else {
              return ROUTES.webTrafficActivityWithParams({
                ...defaultFilters,
                'w.metrics.impressions': '[1,]',
                'w.isEngaged': 'true',
              });
            }
          }}
          unstable_hasRouterContext={false}
          shared
        />
        <Notes />
      </Flare>
    </NotesPanel>
  );
};

export default PixelReachAndEngagementTrendChart;
