import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { TableRef } from 'antd/es/table';
import classNames from 'classnames';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { AudienceOptionType } from '@/api/audience';
import { CampaignActivityDataType, useCampaignActivity } from '@/api/campaign';
import { TRAFFIC_TYPES } from '@/api/common';
import { VendorAndChannelType } from '@/api/vendor';
import { InstantSearchSortOrder } from '@/components/InstantSearch';
import Table, { ColumnsType } from '@/components/Table';
import { usePageFilterContext } from '@/components/page';
import { Link } from '@/components/typography';
import {
  AUDIENCE_PRIMARY,
  BOT_PRIMARY,
  TAM_PRIMARY,
  UNKNOWN_PRIMARY,
  VISITS_PRIMARY,
} from '@/constants';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';
import { VisitsTableBarCell } from '@/features/VisitsTableBarCell';
import didClickOnAntTooltip from '@/helper/didClickOnAntTooltip';
import { numberFormat } from '@/helper/numberFormatter';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { useTableState } from '@/providers/TableState';
import { ROUTES } from '@/router';
import { WebTrafficSearchParamType } from '@/router/searchParams/webTrafficSearchParams';

import styles from './VendorTopCampaignsTable.module.scss';

type Props = {
  vendor: VendorAndChannelType;
  audience?: AudienceOptionType;
  disabledSeries: TRAFFIC_TYPES[];
  maxVisits?: number;
  visitsMode?: 'stack' | 'fill';
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
};

const VendorTopCampaignsTable = ({
  vendor,
  audience,
  disabledSeries,
  maxVisits,
  visitsMode,
  onScroll,
}: Props) => {
  const hasAttributionFlag = useFeatureFlag(FEATURE_FLAG.attribution);
  const tableRef = useRef<TableRef>(null);
  const {
    pageFilters: { startDate, endDate },
  } = usePageFilterContext();
  const { columnsState, columnsMap } = useTableState();
  const { campaignActivity, isLoading, error } = useCampaignActivity(
    {
      sort: { field: 'visits', direction: InstantSearchSortOrder.DESC },
      page: { offset: 0, limit: 5 },
      filter: [
        {
          field: 'vendor.id',
          operator: 'in',
          operand: [vendor.id],
        },
      ],
    },
    startDate,
    endDate,
    audience?.id,
  );
  const [hoveredRow, setHoveredRow] = useState<CampaignActivityDataType | null>(null);
  const [stickyRow, setStickyRow] = useState<{
    element: HTMLElement;
    record: CampaignActivityDataType;
  } | null>(null);

  useOnClickOutside(stickyRow?.element, (event) => {
    if (!didClickOnAntTooltip(event.target)) {
      setStickyRow(null);
    }
  });

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.nativeElement
        .querySelector<HTMLDivElement>('.ant-table-content')
        ?.addEventListener('scroll', (e) =>
          onScroll?.(e as unknown as React.UIEvent<HTMLDivElement>),
        );
    }

    return () => {
      if (tableRef.current) {
        tableRef.current.nativeElement
          .querySelector<HTMLDivElement>('.ant-table-content')
          ?.removeEventListener('scroll', (e) =>
            onScroll?.(e as unknown as React.UIEvent<HTMLDivElement>),
          );
      }
    };
  }, [tableRef]);

  const data = useMemo(() => {
    if (!campaignActivity) {
      return undefined;
    }

    return campaignActivity.concat({
      ad: {
        id: 'summary',
        name: t`All ${vendor.name} Campaigns`,
        vendor,
      },
      adClicks: 0,
      adImpressions: 0,
      allVisits: 0,
      allVisitsBest: false,
      allVisitsWorst: false,
      baseAccounts: 0,
      botVisits: 0,
      closedWonFirstTouchAttribution: 0,
      closedWonInfluenced: 0,
      closedWonLinearAttribution: 0,
      countCampaigns: 0,
      engagedCompanies: 0,
      engagedCompaniesBest: false,
      engagedCompaniesWorst: false,
      fitScore: 0,
      fitScoreBest: false,
      fitScoreWorst: false,
      opportunitiesInfluenced: 0,
      opportunityInfluencedBest: false,
      opportunityInfluencedWorst: false,
      otherVisits: 0,
      percentOfEngagedCompanies: 0,
      percentOpportunitiesInfluenced: 0,
      pipelineFirstTouchAttribution: 0,
      pipelineInfluenced: 0,
      pipelineInfluencedBest: false,
      pipelineInfluencedPerSpend: 0,
      pipelineInfluencedWorst: false,
      pipelineLastTouchAttribution: 0,
      pipelineLinearAttribution: 0,
      spend: 0,
      spendPerVisit: 0,
      spendPerVisitBest: false,
      spendPerVisitWorst: false,
      targetVisits: 0,
      unresolvedVisits: 0,
      viewThroughVisits: 0,
      visitEfficiency: 0,
    });
  }, [campaignActivity, vendor]);

  const cellRender = (index: number, content: ReactNode) => {
    if (data && index === data?.length - 1) {
      return <span className={styles.summaryCellContent} />;
    }
    return content;
  };

  const columns: ColumnsType<CampaignActivityDataType> = useMemo(
    () => [
      {
        title: '',
        key: 'group',
        fixed: 'left',
        className: styles.rowSpanColumn,
        width: 152,
        render: () => <Trans>Campaigns</Trans>,
        onCell: (_, index) => (index === 0 ? { rowSpan: data?.length } : { rowSpan: 0 }),
      },
      {
        title: t`Campaign Name`,
        key: 'name',
        fixed: 'left',
        width: 200,
        render: (text, field, index) =>
          data?.length && index === data?.length - 1 ? (
            <Link
              className={styles.summaryCellContent}
              variant="caption1"
              color="aqua"
              to={ROUTES.campaignsWithParams({ 'c.vendor.id': [vendor.id] })}
            >
              {field.ad.name}
            </Link>
          ) : (
            <Link variant="caption1" to={ROUTES.campaignById(field.ad.id)}>
              {field.ad.name}
            </Link>
          ),
      },
      {
        title: t`Spend`,
        key: 'spend',
        hidden: columnsState.hiddenColumns.includes('spend'),
        width: columnsMap.spend?.width,
        align: columnsMap.spend?.align,
        render: (text, _, index) =>
          cellRender(index, numberFormat(text, { isCurrency: true, precision: 0 })),
      },
      {
        title: t`Impressions`,
        key: 'adImpressions',
        hidden: columnsState.hiddenColumns.includes('adImpressions'),
        width: columnsMap.adImpressions?.width,
        align: columnsMap.adImpressions?.align,
        render: (text, _, index) => cellRender(index, numberFormat(text)),
      },
      {
        title: t`Clicks`,
        key: 'adClicks',
        hidden: columnsState.hiddenColumns.includes('adClicks'),
        width: columnsMap.adClicks?.width,
        align: columnsMap.adClicks?.align,
        render: (text, _, index) => cellRender(index, numberFormat(text)),
      },
      {
        title: t`Visits`,
        key: 'visits',
        hidden: columnsState.hiddenColumns.includes('visits'),
        width: columnsMap.visits?.width,
        align: columnsMap.visits?.align,
        render: (_, field, index) =>
          cellRender(
            index,
            <VisitsTableBarCell
              data={[
                {
                  type: audience?.id ? TRAFFIC_TYPES.AUDIENCE : TRAFFIC_TYPES.TAM,
                  label: audience?.name ?? t`TAM`,
                  value: field.targetVisits,
                  color: audience?.id ? AUDIENCE_PRIMARY : TAM_PRIMARY,
                  isDisabled: audience?.id
                    ? disabledSeries.includes(TRAFFIC_TYPES.AUDIENCE)
                    : disabledSeries.includes(TRAFFIC_TYPES.TAM),
                },
                {
                  type: TRAFFIC_TYPES.OTHER,
                  label: t`Other Companies`,
                  value: field.otherVisits,
                  color: VISITS_PRIMARY,
                  isDisabled: disabledSeries.includes(TRAFFIC_TYPES.OTHER),
                },
                {
                  type: TRAFFIC_TYPES.BOT,
                  label: t`Bot`,
                  value: field.botVisits,
                  color: BOT_PRIMARY,
                  isDisabled: disabledSeries.includes(TRAFFIC_TYPES.BOT),
                },
                {
                  type: TRAFFIC_TYPES.UNRESOLVED,
                  label: t`Unknown`,
                  value: field.unresolvedVisits,
                  color: UNKNOWN_PRIMARY,
                  isDisabled: disabledSeries.includes(TRAFFIC_TYPES.UNRESOLVED),
                },
              ]}
              maxVisits={maxVisits}
              displayMode={visitsMode}
              isOpaque={
                (stickyRow == null && hoveredRow == null) ||
                stickyRow?.record === field ||
                hoveredRow === field
              }
              isTooltipOpen={hoveredRow === field || stickyRow?.record === field}
              isTooltipSticky={stickyRow?.record === field}
              tooltipTitle={field.ad.name}
              tooltipLink={(trafficType: TRAFFIC_TYPES) => {
                const queryParams = {
                  'w.campaignIds': [field.ad.id],
                  'w.metrics.visits': '[1,]',
                } as WebTrafficSearchParamType;

                if (trafficType === TRAFFIC_TYPES.AUDIENCE && audience?.id) {
                  return ROUTES.webTrafficActivityWithParams({
                    ...queryParams,
                    'w.audienceIds': audience.id,
                  });
                } else if (trafficType === TRAFFIC_TYPES.TAM) {
                  return ROUTES.webTrafficActivityWithParams({
                    ...queryParams,
                    'w.inTam': 'true',
                  });
                } else if (trafficType === TRAFFIC_TYPES.OTHER) {
                  if (audience?.id) {
                    return ROUTES.webTrafficActivityWithParams({
                      ...queryParams,
                      ['w.audienceIds!']: audience.id,
                    });
                  }
                  return ROUTES.webTrafficActivityWithParams({
                    ...queryParams,
                    'w.inTam': 'false',
                  });
                } else if (trafficType === TRAFFIC_TYPES.ALL) {
                  return ROUTES.webTrafficActivityWithParams(queryParams);
                }

                return undefined;
              }}
              onStickyTooltipClose={() => setStickyRow(null)}
            />,
          ),
        onCell: (record) => ({
          style: { cursor: 'pointer' },
          onMouseEnter: () => setHoveredRow(record),
          onMouseLeave: () => setHoveredRow(null),
          onClick: (e) => {
            if (didClickOnAntTooltip(e.target)) {
              return;
            }

            // clicking on the same row again will close the tooltip
            if (stickyRow?.record.ad.id === record.ad.id) {
              setStickyRow(null);
            } else {
              setStickyRow({
                element: e.currentTarget as HTMLElement,
                record,
              });
            }
          },
        }),
      },
      {
        title: t`Visit Efficiency`,
        key: 'visitEfficiency',
        hidden: columnsState.hiddenColumns.includes('visitEfficiency'),
        width: columnsMap.visitEfficiency?.width,
        align: columnsMap.visitEfficiency?.align,
        render: (_, field, index) =>
          cellRender(index, numberFormat(field.visitEfficiency, { isPercent: true, precision: 1 })),
      },
      {
        title: t`View-Through Visits`,
        key: 'viewThroughVisits',
        hidden: columnsState.hiddenColumns.includes('viewThroughVisits'),
        width: columnsMap.viewThroughVisits?.width,
        align: columnsMap.viewThroughVisits?.align,
        render: (_, field, index) => cellRender(index, numberFormat(field.viewThroughVisits)),
      },
      {
        title: t`Engaged Companies`,
        key: 'engagedCompanies',
        hidden: columnsState.hiddenColumns.includes('engagedCompanies'),
        width: columnsMap.engagedCompanies?.width,
        align: columnsMap.engagedCompanies?.align,
        render: (text, field, index) =>
          cellRender(
            index,
            +text > 0 ? (
              <Link
                variant="caption1"
                to={ROUTES.webTrafficActivityWithParams({
                  'w.campaignIds': field.ad.id,
                  'w.audienceIds': audience?.id,
                  'w.metrics.visits': '[1,]',
                })}
              >
                {numberFormat(text)}
              </Link>
            ) : (
              0
            ),
          ),
      },
      {
        title: t`Pipeline Influence`,
        key: 'pipelineInfluenced',
        hidden: columnsState.hiddenColumns.includes('pipelineInfluenced'),
        width: columnsMap.pipelineInfluenced?.width,
        align: columnsMap.pipelineInfluenced?.align,
        render: (text, _, index) =>
          cellRender(index, numberFormat(text, { isCurrency: true, precision: 0 })),
      },
      {
        title: t`Closed-Won Influence`,
        key: 'closedWonInfluenced',
        hidden: columnsState.hiddenColumns.includes('closedWonInfluenced'),
        width: columnsMap.closedWonInfluenced?.width,
        align: columnsMap.closedWonInfluenced?.align,
        render: (text, _, index) =>
          cellRender(index, numberFormat(text, { isCurrency: true, precision: 0 })),
      },
      {
        title: t`First-Touch Pipeline`,
        key: 'pipelineFirstTouchAttribution',
        hidden:
          columnsState.hiddenColumns.includes('pipelineFirstTouchAttribution') ||
          !hasAttributionFlag,
        width: columnsMap.pipelineFirstTouchAttribution?.width,
        align: columnsMap.pipelineFirstTouchAttribution?.align,
        render: (text, _, index) =>
          cellRender(index, numberFormat(text, { isCurrency: true, precision: 0 })),
      },
      {
        title: t`First-Touch Closed-Won`,
        key: 'closedWonFirstTouchAttribution',
        hidden:
          columnsState.hiddenColumns.includes('closedWonFirstTouchAttribution') ||
          !hasAttributionFlag,
        width: columnsMap.closedWonFirstTouchAttribution?.width,
        align: columnsMap.closedWonFirstTouchAttribution?.align,
        render: (text, _, index) =>
          cellRender(index, numberFormat(text, { isCurrency: true, precision: 0 })),
      },
      {
        title: t`Last-Touch Pipeline`,
        key: 'pipelineLastTouchAttribution',
        hidden:
          columnsState.hiddenColumns.includes('pipelineLastTouchAttribution') ||
          !hasAttributionFlag,
        width: columnsMap.pipelineLastTouchAttribution?.width,
        align: columnsMap.pipelineLastTouchAttribution?.align,
        render: (text, _, index) =>
          cellRender(index, numberFormat(text, { isCurrency: true, precision: 0 })),
      },
      {
        title: t`Linear Pipeline`,
        key: 'pipelineLinearAttribution',
        hidden:
          columnsState.hiddenColumns.includes('pipelineLinearAttribution') || !hasAttributionFlag,
        width: columnsMap.pipelineLinearAttribution?.width,
        align: columnsMap.pipelineLinearAttribution?.align,
        render: (text, _, index) =>
          cellRender(index, numberFormat(text, { isCurrency: true, precision: 0 })),
      },
      {
        title: t`Linear Closed-Won`,
        key: 'closedWonLinearAttribution',
        hidden:
          columnsState.hiddenColumns.includes('closedWonLinearAttribution') || !hasAttributionFlag,
        width: columnsMap.closedWonLinearAttribution?.width,
        align: columnsMap.closedWonLinearAttribution?.align,
        render: (text, _, index) =>
          cellRender(index, numberFormat(text, { isCurrency: true, precision: 0 })),
      },
    ],
    [
      data,
      columnsMap,
      maxVisits,
      visitsMode,
      audience,
      hoveredRow,
      stickyRow,
      disabledSeries,
      columnsState.hiddenColumns,
      hasAttributionFlag,
    ],
  );

  const sortedColumns = useMemo(() => {
    return [...columns].sort((a, b) => {
      const aIndex = columnsState.sortedColumns.indexOf(`${a.key}`);
      const bIndex = columnsState.sortedColumns.indexOf(`${b.key}`);
      return aIndex - bIndex;
    });
  }, [columnsState.sortedColumns, columns]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerLoading]: isLoading && !data && !error,
      })}
    >
      <Table<CampaignActivityDataType>
        ref={tableRef}
        className={styles.table}
        emptyMessage={<Trans>No campaigns found</Trans>}
        dataSource={data}
        columns={sortedColumns}
        loading={isLoading}
        loaderRows={4}
        error={error}
        pagination={false}
        showHeader={false}
        rowKey={(record) => record.ad.id}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default VendorTopCampaignsTable;
