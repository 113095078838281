import { t } from '@lingui/core/macro';
import { useMemo } from 'react';

import { Select } from '@/components/Form';

type Props = {
  value: 'pipeline' | 'closedWon';
  onChange: (oppType: 'pipeline' | 'closedWon') => void;
};

export const OpportunityTypeSelect = ({ value, onChange }: Props) => {
  const oppTypeOptions = useMemo(
    () => [
      { value: 'pipeline', label: t`Pipeline` },
      { value: 'closedWon', label: t`Closed-Won` },
    ],
    [],
  );

  const handleChange = (value: string) => {
    onChange(value as 'pipeline' | 'closedWon');
  };

  return (
    <Select
      width={175}
      placeholder={t`Select Opp Type`}
      value={value}
      options={oppTypeOptions}
      onChange={handleChange}
    />
  );
};
