import { i18n } from '@lingui/core';
import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Col } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

import { AudienceOptionType } from '@/api/audience';
import {
  GoalKPIMetricType,
  NotificationPageMeta,
  NotificationSubscriptionGoalDataType,
} from '@/api/settings';
import Form, { DatePicker, NumberInput, Select } from '@/components/Form';
import { DateBEFormat } from '@/constants';
import { numberFormat } from '@/helper/numberFormatter';
import { useHeaderContext } from '@/hooks/header/HeaderContext';

import NotificationSubscriptionFormCommon from './NotificationSubscriptionFormCommon';
import { FORM_FIELD } from './NotificationSubscriptionModal';

type Props = {
  subscription?: NotificationSubscriptionGoalDataType | null;
  audience?: AudienceOptionType;
  notificationPageMeta?: NotificationPageMeta;
  isEditing: boolean;
  isSaving: boolean;
};

const NotificationSubscriptionGoalForm = ({
  subscription,
  audience,
  notificationPageMeta,
  isEditing,
  isSaving,
}: Props) => {
  const { title } = useHeaderContext();
  const kpiMetricOptions = useMemo<GoalKPIMetricType[] | undefined>(
    () =>
      notificationPageMeta?.kpiMetrics?.map((metricOption) => ({
        value: metricOption.value,
        label: i18n._(metricOption.label),
        allowsAudience: metricOption.allowsAudience,
      })),
    [notificationPageMeta],
  );
  const [kpiMetric, setKpiMetric] = useState<GoalKPIMetricType | undefined>(kpiMetricOptions?.[0]);

  const getDefaultReportName = useCallback(() => {
    const reportNameParts = [t`${kpiMetric ? `${kpiMetric.label} ` : ''}Goal Reached!`];
    if (title) {
      reportNameParts.push(title);
    }
    if (kpiMetric?.allowsAudience) {
      reportNameParts.push(audience ? audience.name : t`TAM`);
    }
    return reportNameParts.join(' - ');
  }, [notificationPageMeta, title, audience?.name, kpiMetric]);

  return (
    <>
      <NotificationSubscriptionFormCommon
        subscription={subscription}
        defaultReportName={getDefaultReportName()}
        isSaving={isSaving}
      />
      <Col md={10} sm={24} xs={24}>
        <Form.Item
          label={<Trans>KPI</Trans>}
          name={FORM_FIELD.KPI}
          initialValue={
            subscription?.metric ??
            (kpiMetricOptions != null && kpiMetricOptions.length > 0
              ? kpiMetricOptions[0].value
              : '')
          }
          rules={[{ required: true, message: <Trans>KPI is Required</Trans> }]}
          onReset={() => setKpiMetric(kpiMetricOptions?.[0])}
        >
          <Select
            placeholder={t`Choose KPI`}
            isDisabled={isEditing || isSaving}
            options={kpiMetricOptions}
            onChange={(_, option) =>
              setKpiMetric((Array.isArray(option) ? option?.[0] : option) as GoalKPIMetricType)
            }
          />
        </Form.Item>
      </Col>
      <Col md={7} sm={12} xs={12}>
        <Form.Item
          label={<Trans>Goal</Trans>}
          name={FORM_FIELD.GOAL}
          initialValue={subscription?.goal || ''}
          rules={[{ required: true, message: <Trans>Goal is Required</Trans> }]}
        >
          <NumberInput
            placeholder={t`Enter Goal`}
            formatter={(value) => (value ? numberFormat(value) : '')}
            isDisabled={isSaving}
          />
        </Form.Item>
      </Col>
      <Col md={7} sm={12} xs={12}>
        <Form.Item
          label={<Trans>Goal Start Date</Trans>}
          name={FORM_FIELD.GOAL_START_DATE}
          getValueFromEvent={(val?: Dayjs) => val?.format(DateBEFormat)}
          getValueProps={(val?: string) => ({ value: val ? dayjs(val) : '' })}
          initialValue={
            subscription?.goalStartDate
              ? dayjs(subscription.goalStartDate)
              : dayjs().startOf('d')?.format(DateBEFormat)
          }
          rules={[{ required: true, message: <Trans>Date is Required</Trans> }]}
        >
          <DatePicker
            maxDate={dayjs().endOf('d')}
            size="large"
            style={{ width: '100%' }}
            isDisabled={isSaving}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default NotificationSubscriptionGoalForm;
