import { t } from '@lingui/core/macro';
import { useMemo } from 'react';

import { useCampaignKPIs } from '@/api/campaign';
import { usePageFilterContext } from '@/components/page';
import { TrafficKPIs } from '@/features/TrafficKPIs';
import { ROUTES } from '@/router';

const CampaignOverviewKPIMetrics = () => {
  const {
    pageFilters: { startDate, endDate, audience },
  } = usePageFilterContext();

  const {
    campaignKPIs: data,
    isLoading,
    error,
  } = useCampaignKPIs(undefined, startDate, endDate, audience?.id);

  const trafficKPIData = useMemo(() => {
    const {
      visits,
      visitsLastPeriod,
      visitsPercentChange,
      tamVisits,
      tamVisitsLastPeriod,
      tamVisitsPercentChange,
      audienceVisits,
      audienceVisitsLastPeriod,
      audienceVisitsPercentChange,
      engagedCompanies,
      engagedCompaniesLastPeriod,
      engagedCompaniesPercentChange,
      pipelineInfluenced,
      pipelineInfluencedLastPeriod,
      pipelineInfluencedPercentChange,
      closedWonInfluenced,
      closedWonInfluencedLastPeriod,
      closedWonInfluencedPercentChange,
    } = data ?? {};

    const secondItem = audience?.id
      ? {
          id: 'targetVisits',
          label: t`${audience.name} Visits`,
          value: audienceVisits,
          lastPeriod: audienceVisitsLastPeriod,
          percentChange: audienceVisitsPercentChange,
          valueFormat: {},
        }
      : {
          id: 'targetVisits',
          label: t`TAM Visits`,
          value: tamVisits,
          lastPeriod: tamVisitsLastPeriod,
          percentChange: tamVisitsPercentChange,
          valueFormat: {},
        };

    return [
      {
        id: 'visits',
        label: t`Total Visits`,
        value: visits,
        lastPeriod: visitsLastPeriod,
        percentChange: visitsPercentChange,
        valueFormat: {},
      },
      secondItem,
      {
        id: 'engagedCompanies',
        label: audience?.name ? t`${audience.name} Engaged Companies` : t`TAM Engaged Companies`,
        value: engagedCompanies,
        valueLink:
          engagedCompanies != null &&
          engagedCompanies > 0 &&
          ROUTES.webTrafficActivityWithParams({
            'w.audienceIds': audience?.id,
            'w.inTam': audience?.id ? undefined : 'true',
            'w.metrics.visits': '[1,]',
          }),
        lastPeriod: engagedCompaniesLastPeriod,
        percentChange: engagedCompaniesPercentChange,
        valueFormat: {},
      },
      {
        id: 'pipeline',
        label: t`Pipeline Influence`,
        value: pipelineInfluenced,
        lastPeriod: pipelineInfluencedLastPeriod,
        percentChange: pipelineInfluencedPercentChange,
        valueFormat: { isCurrency: true, precision: 0 },
      },
      {
        id: 'closedWon',
        label: t`Closed-Won Influence`,
        value: closedWonInfluenced,
        lastPeriod: closedWonInfluencedLastPeriod,
        percentChange: closedWonInfluencedPercentChange,
        valueFormat: { isCurrency: true, precision: 0 },
      },
    ];
  }, [data]);

  return <TrafficKPIs trafficKPIData={trafficKPIData} isLoading={isLoading} error={error} />;
};

export default CampaignOverviewKPIMetrics;
