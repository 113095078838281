import { t } from '@lingui/core/macro';
import gql from 'graphql-tag';
import { useMemo } from 'react';

import { OptionType } from '@/api/common';
import { OpportunityStatusType } from '@/api/opportunity';
import { getVariablesFromTableParamsNew } from '@/api/util/getVariablesFromTableParams';
import { InstantSearchSortOrder, QueryState, SolQueryParamsNew } from '@/components/InstantSearch';
import { CAMPAIGN_GROUP_MAX_LIMIT } from '@/constants';
import { useSolInfiniteQuery, useSolQuery } from '@/hooks/useSolQuery';

import {
  CampaignActivityGQLResponse,
  CampaignEfficiencyGQLResponse,
  CampaignKPIsGQLResponse,
  CampaignOpportunitiesInfluencedGQLResponse,
  CampaignOpportunitiesInfluencedListGQLResponse,
  CampaignOptionGQLResponseType,
  CampaignRomsGQLResponse,
  CampaignType,
  CampaignsByIdGQLResponse,
} from './campaignType';

const isCampaignDetailLimitMet = (campaignIds?: string[]) =>
  campaignIds?.length && campaignIds.length <= CAMPAIGN_GROUP_MAX_LIMIT;

const replaceUnassignedName = (campaign: CampaignType, { includeVendorName = false } = {}) => {
  if (campaign?.id.includes('.unassigned__')) {
    return {
      ...campaign,
      name: includeVendorName
        ? t`Unassigned - ${campaign.vendor.name} - ${campaign.vendor.channel?.name ?? ''}`
        : t`Unassigned`,
    };
  }
  return campaign;
};

const campaignActivityQuery = gql`
  query CampaignActivity(
    $page: Pagination!
    $sort: [SortParamInput]!
    $filter: [FilterParamInput]!
    $startDate: DateTime!
    $endDate: DateTime!
    $audienceId: String
  ) {
    visualization {
      campaignActivity(
        page: $page
        sort: $sort
        filter: $filter
        startDate: $startDate
        endDate: $endDate
        audienceId: $audienceId
      ) {
        edges {
          node {
            ad {
              id
              name
              vendor {
                id
                name
                channel {
                  id
                  name
                }
              }
            }
            adClicks
            adImpressions
            allVisits: visits
            allVisitsBest: visitsBest
            allVisitsWorst: visitsWorst
            baseAccounts
            botVisits
            closedWonFirstTouchAttribution
            closedWonInfluenced
            closedWonLinearAttribution
            countCampaigns
            engagedCompanies
            engagedCompaniesBest
            engagedCompaniesWorst
            fitScore
            fitScoreBest
            fitScoreWorst
            opportunitiesInfluenced
            opportunityInfluencedBest
            opportunityInfluencedWorst
            otherVisits
            percentOfEngagedCompanies
            percentOpportunitiesInfluenced
            pipelineFirstTouchAttribution
            pipelineInfluenced
            pipelineInfluencedBest
            pipelineInfluencedPerSpend
            pipelineInfluencedWorst
            pipelineLastTouchAttribution
            pipelineLinearAttribution
            spend
            spendPerVisit
            spendPerVisitBest
            spendPerVisitWorst
            targetVisits
            viewThroughVisits
            visitEfficiency
            unresolvedVisits
          }
        }
        totalEdges
      }
    }
  }
`;

export const useCampaignActivity = (
  tableParams?: SolQueryParamsNew,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = tableParams && startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSolQuery<CampaignActivityGQLResponse>({
    query: !!canExecuteQuery && campaignActivityQuery,
    variables: {
      ...getVariablesFromTableParamsNew(tableParams),
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId,
    },
  });

  const campaignActivity = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return data.visualization.campaignActivity.edges.map(({ node }) => ({
      ...node,
      ad: replaceUnassignedName(node.ad),
    }));
  }, [data]);

  return {
    campaignActivity,
    totalResults: data?.visualization.campaignActivity.totalEdges,
    isLoading,
    error,
  };
};

export const useCampaignActivityFitScore = (
  campaignIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = campaignIds?.length && startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSolQuery<CampaignActivityGQLResponse>({
    query: !!canExecuteQuery && campaignActivityQuery,
    variables: {
      page: {
        offset: 0,
        limit: 50,
      },
      sort: [
        {
          field: 'fitScore',
          direction: 'desc',
        },
      ],
      filter: [
        {
          field: 'id',
          operator: 'in',
          operand: campaignIds,
        },
      ],
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId,
    },
  });

  const fitScoreData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return data.visualization.campaignActivity.edges.map(({ node }) => ({
      ...node,
      ad: replaceUnassignedName(node.ad),
    }));
  }, [data]);

  return {
    fitScoreData,
    isLoading,
    error,
  };
};

export const useCampaignKPIs = (
  campaignIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSolQuery<CampaignKPIsGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query CampaignKpis(
          $campaignIds: [String]
          $startDate: DateTime!
          $endDate: DateTime!
          $audienceIds: [String]
        ) {
          visualization {
            campaignKpi(
              campaignIds: $campaignIds
              startDate: $startDate
              endDate: $endDate
              audienceIds: $audienceIds
            ) {
              visits
              visitsLastPeriod
              visitsPercentChange
              impressions
              impressionsLastPeriod
              impressionsPercentChange
              tamVisits
              tamVisitsLastPeriod
              tamVisitsPercentChange
              audienceVisits
              audienceVisitsLastPeriod
              audienceVisitsPercentChange
              viewThroughVisits
              viewThroughVisitsLastPeriod
              viewThroughVisitsPercentChange
              reachedCompanies
              reachedCompaniesLastPeriod
              reachedCompaniesPercentChange
              engagedCompanies
              engagedCompaniesLastPeriod
              engagedCompaniesPercentChange
              pipelineInfluenced
              pipelineInfluencedLastPeriod
              pipelineInfluencedPercentChange
              pipelineOpportunities
              closedWonInfluenced
              closedWonInfluencedLastPeriod
              closedWonInfluencedPercentChange
              closedWonOpportunities
              percentEfficiency
              roms
              spend
            }
          }
        }
      `,
    variables: {
      campaignIds: campaignIds ? campaignIds : [],
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds: audienceId ? [audienceId] : [],
    },
  });

  return {
    campaignKPIs: data?.visualization.campaignKpi,
    isLoading,
    error,
  };
};

export const useCampaignsByIds = (campaignIds?: string[]) => {
  const campaignSnippet = campaignIds
    ?.map(
      // eslint-disable-next-line lingui/no-unlocalized-strings
      (id, index) => `
    campaign${index + 1}: getById(id: $id${index + 1}) {
      id
      name
      vendor {
        id
        name
        channel {
          id
          name
          color
        }
      }
    }
  `,
    )
    .join('\n');

  // eslint-disable-next-line lingui/no-unlocalized-strings
  const query = `query GetCampaignsByIds(${campaignIds?.map((_, index) => `$id${index + 1}: String!`).join(', ')}) {
    campaigns {
      ${campaignSnippet}
    }
  }`;

  const { data, isLoading, error } = useSolQuery<CampaignsByIdGQLResponse>({
    query:
      !!isCampaignDetailLimitMet(campaignIds) &&
      gql`
        ${query}
      `,
    variables: campaignIds?.reduce<Record<string, string>>((acc, id, index) => {
      acc[`id${index + 1}`] = id;
      return acc;
    }, {}),
  });

  const campaigns = useMemo(
    () =>
      data &&
      Object.keys(data?.campaigns).map((key) => ({
        ...replaceUnassignedName(data?.campaigns[key]),
      })),
    [data],
  );

  return {
    campaigns,
    isLoading,
    error,
  };
};

export const useROMSByCampaignId = (
  campaignIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = campaignIds?.length && startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSolQuery<CampaignRomsGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query CampaignRoms(
          $adIds: [String]
          $startDate: DateTime!
          $endDate: DateTime!
          $audienceIds: [String]
        ) {
          visualization {
            campaignRoms(
              adIds: $adIds
              startDate: $startDate
              endDate: $endDate
              audienceIds: $audienceIds
            ) {
              channelAverageRoms
              industryAverageRoms
              roms
            }
          }
        }
      `,
    variables: {
      adIds: campaignIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds: audienceId ? [audienceId] : [],
    },
  });

  return {
    romsData: data?.visualization.campaignRoms,
    isLoading,
    error,
  };
};

export const useCampaignEfficiency = (
  campaignIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = campaignIds?.length && startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSolQuery<CampaignEfficiencyGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query CampaignEfficiency(
          $adIds: [String!]!
          $startDate: DateTime
          $endDate: DateTime
          $audienceIds: [String]
        ) {
          visualization {
            campaignEfficiency(
              adIds: $adIds
              startDate: $startDate
              endDate: $endDate
              audienceIds: $audienceIds
            ) {
              allVisits
              botVisits
              tamVisits
              audienceVisits
              otherNonAudienceVisits
              otherNonTamVisits
              unresolvedVisits
            }
          }
        }
      `,
    variables: {
      adIds: campaignIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds: audienceId ? [audienceId] : [],
    },
  });

  const efficiencyData = useMemo(() => {
    const campaignEfficiency = data?.visualization.campaignEfficiency[0];

    if (!campaignEfficiency) {
      return undefined;
    }

    return {
      allVisits: campaignEfficiency.allVisits,
      audienceVisits: audienceId ? campaignEfficiency.audienceVisits : campaignEfficiency.tamVisits,
      otherVisits: audienceId
        ? campaignEfficiency.otherNonAudienceVisits
        : campaignEfficiency.otherNonTamVisits,
      botVisits: campaignEfficiency.botVisits,
      unresolvedVisits: campaignEfficiency.unresolvedVisits,
    };
  }, [audienceId, data]);

  return {
    efficiencyData,
    isLoading,
    error,
  };
};

export const useCampaignOpportunitiesInfluenced = (
  campaignIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = startDate && endDate && isCampaignDetailLimitMet(campaignIds);

  const { data, isLoading, error } = useSolQuery<CampaignOpportunitiesInfluencedGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query CampaignOpportunitiesInfluenced(
          $adIds: [String]
          $startDate: DateTime
          $endDate: DateTime
          $audienceIds: [String]
        ) {
          visualization {
            campaignOpportunitiesInfluenced(
              adIds: $adIds
              startDate: $startDate
              endDate: $endDate
              audienceIds: $audienceIds
            ) {
              pipeline {
                data {
                  sundayOfWeek
                  opportunities
                  revenue
                }
                totalOpportunities
                totalRevenue
              }
              recentDeals {
                data {
                  sundayOfWeek
                  opportunities
                  revenue
                }
                totalOpportunities
                totalRevenue
              }
            }
          }
        }
      `,
    variables: {
      adIds: campaignIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds: audienceId ? [audienceId] : [],
    },
  });

  return {
    campaignOpportunitiesInfluenced: data?.visualization.campaignOpportunitiesInfluenced[0],
    isLoading,
    error,
  };
};

export const useCampaignOpportunitiesInfluencedList = (
  pageState: Omit<QueryState, 'search' | 'filters'>,
  opportunityType: OpportunityStatusType,
  campaignIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = startDate && endDate && isCampaignDetailLimitMet(campaignIds);
  // The 'date' column doesn't actually exist for an opportunity. We'll change it to either an open
  // or closed date based on the influenceType.
  if (pageState.sortBy === 'date') {
    pageState = {
      ...pageState,
      sortBy: opportunityType === 'pipeline' ? 'openDate' : 'closeDate',
    };
  }

  const { data, isLoading, error } = useSolQuery<CampaignOpportunitiesInfluencedListGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query CampaignOpportunitiesInfluencedList(
          $adIds: [String!]!
          $sort: [SortParamInput]!
          $startDate: DateTime
          $endDate: DateTime
          $audienceIds: [String]
          $influenceType: OppInfluence
          $page: Pagination!
        ) {
          visualization {
            campaignOpportunitiesInfluencedList(
              adIds: $adIds
              startDate: $startDate
              endDate: $endDate
              audienceIds: $audienceIds
              influenceType: $influenceType
              page: $page
              sort: $sort
            ) {
              edges {
                node {
                  id
                  name
                  metrics {
                    impressions
                    visits
                  }
                  openDate
                  closeDate
                  amount
                  account {
                    name
                    company {
                      name
                    }
                  }
                }
              }
              totalEdges
            }
          }
        }
      `,
    variables: {
      influenceType: opportunityType === 'pipeline' ? 'PIPELINE' : 'RECENTDEAL',
      adIds: campaignIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds: audienceId ? [audienceId] : [],
      page: {
        offset: (pageState.page - 1) * pageState.size,
        limit: pageState.size,
      },
      sort: {
        field: pageState.sortBy,
        direction: pageState.sortOrder,
      },
    },
  });

  return {
    opportunitiesListData: data?.visualization.campaignOpportunitiesInfluencedList.edges.map(
      (edge) => edge.node,
    ),
    totalResults: data?.visualization.campaignOpportunitiesInfluencedList.totalEdges,
    isLoading,
    error,
  };
};

export const useCampaignOptions = ({
  search = '',
  pageSize = 50,
  prependedOptions = [],
  startDate,
  endDate,
}: {
  search?: string;
  pageSize?: number;
  prependedOptions?: OptionType[];
  startDate?: string;
  endDate?: string;
}) => {
  const { data, isLoading, isFetchingNextPage, error, fetchNextPage } =
    useSolInfiniteQuery<CampaignOptionGQLResponseType>({
      queryKey: ['campaignOptions', search, startDate, endDate],
      queryFn: ({ pageParam = 0, signal }) => {
        const canExecuteQuery = startDate && endDate;

        return {
          query:
            !!canExecuteQuery &&
            gql`
              query GetCampaignOptions(
                $page: Pagination!
                $sort: [SortParamInput]!
                $filter: [FilterParamInput]!
                $endDate: DateTime!
                $startDate: DateTime!
              ) {
                visualization {
                  campaignActivity(
                    page: $page
                    sort: $sort
                    filter: $filter
                    endDate: $endDate
                    startDate: $startDate
                  ) {
                    edges {
                      node {
                        ad {
                          id
                          name
                          vendor {
                            id
                            name
                            channel {
                              id
                              name
                            }
                          }
                        }
                      }
                    }
                    pageMeta {
                      hasNext
                    }
                  }
                }
              }
            `,
          variables: {
            ...getVariablesFromTableParamsNew({
              page: {
                offset: pageParam * pageSize,
                limit: pageSize,
              },
              sort: {
                direction: InstantSearchSortOrder.ASC,
                field: 'campaign.name',
              },
              filter: search
                ? [{ field: 'campaign.name', operator: 'iLike', operand: `%${search}%` }]
                : [],
            }),
            startDate: startDate + 'T00:00:00Z',
            endDate: endDate + 'T23:59:59Z',
          },
          signal,
        };
      },
      getNextPageParam: (lastPage, pages) => {
        return lastPage.visualization.campaignActivity.pageMeta.hasNext
          ? pages.length + 1
          : undefined;
      },
    });

  const hasMore =
    data?.pages?.[data.pages.length - 1].visualization.campaignActivity.pageMeta.hasNext;

  const campaignOptions = useMemo(() => {
    if (!data) {
      return prependedOptions;
    }

    return (prependedOptions || []).concat(
      data.pages
        .map((d) =>
          d.visualization.campaignActivity.edges.map((edge) =>
            replaceUnassignedName(edge.node.ad, { includeVendorName: true }),
          ),
        )
        .flat(),
    );
  }, [data?.pages, prependedOptions]);

  return {
    campaignOptions,
    hasMore,
    isLoading,
    isFetchingNextPage,
    error,
    fetchNextPage,
  };
};
