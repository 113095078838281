import { t } from '@lingui/core/macro';
import gql from 'graphql-tag';
import { useMemo } from 'react';

import { AudienceOptionType } from '@/api/audience';
import { getVariablesFromTableParamsNew } from '@/api/util/getVariablesFromTableParams';
import { SolQueryParamsNew } from '@/components/InstantSearch';
import { useSolImmutableQuery, useSolQuery } from '@/hooks/useSolQuery';
import { ROUTES } from '@/router';

import {
  OfflineActivityGQLResponse,
  VendorActivityGQLResponse,
  VendorActivityKPIsGQLResponse,
  VendorListByChannelIdGQLResponse,
  VendorListGQLResponse,
  VendorTrafficOverviewKPIsGQLResponse,
  VendorWeeklyVisitsGQLResponse,
} from './vendorType';

export const useVendorList = () => {
  const { data, error, isLoading } = useSolImmutableQuery<VendorListGQLResponse>({
    query: gql`
      query GetVendorList {
        vendors {
          getAll {
            id
            name
            channel {
              id
              name
            }
          }
        }
      }
    `,
  });

  const vendors = data?.vendors.getAll.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );

  return {
    vendors,
    error,
    isLoading,
  };
};

export const useVendorById = (vendorId?: string) => {
  const { vendors, isLoading, error } = useVendorList();
  const vendor = vendors?.find((item) => item.id === vendorId);

  return {
    vendor,
    error,
    isLoading,
  };
};

export const useVendorListByChannelId = (channelId?: string) => {
  const { data, error, isLoading } = useSolImmutableQuery<VendorListByChannelIdGQLResponse>({
    query:
      !!channelId &&
      gql`
        query GetVendorListByChannelId($channelId: String) {
          vendors {
            getByChannelId(channelId: $channelId) {
              id
              name
            }
          }
        }
      `,
    variables: {
      channelId,
    },
  });

  const vendors = data?.vendors.getByChannelId.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );

  return {
    vendors,
    error,
    isLoading,
  };
};

export const useVendorActivityKPIs = (
  startDate?: string,
  endDate?: string,
  audience?: AudienceOptionType,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSolQuery<VendorActivityKPIsGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query VendorTrafficKPIs($startDate: DateTime!, $endDate: DateTime!, $audienceId: String) {
          visualization {
            trafficKpi(startDate: $startDate, endDate: $endDate, audienceId: $audienceId) {
              audienceVisits
              audienceVisitsLastPeriod
              audienceVisitsPercentChange
              closedWonInfluenced
              closedWonInfluencedLastPeriod
              closedWonInfluencedPercentChange
              engagedCompanies
              engagedCompaniesLastPeriod
              engagedCompaniesPercentChange
              pipelineInfluenced
              pipelineInfluencedLastPeriod
              pipelineInfluencedPercentChange
              tamVisits
              tamVisitsLastPeriod
              tamVisitsPercentChange
              visits
              visitsLastPeriod
              visitsPercentChange
            }
          }
        }
      `,
    variables: {
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId: audience?.id,
    },
  });

  const vendorActivityKpis = useMemo(() => {
    const {
      visits,
      visitsLastPeriod,
      visitsPercentChange,
      tamVisits,
      tamVisitsLastPeriod,
      tamVisitsPercentChange,
      audienceVisits,
      audienceVisitsLastPeriod,
      audienceVisitsPercentChange,
      engagedCompanies,
      engagedCompaniesLastPeriod,
      engagedCompaniesPercentChange,
      pipelineInfluenced,
      pipelineInfluencedLastPeriod,
      pipelineInfluencedPercentChange,
      closedWonInfluenced,
      closedWonInfluencedLastPeriod,
      closedWonInfluencedPercentChange,
    } = data?.visualization.trafficKpi ?? {};

    const secondItem = audience?.id
      ? {
          id: 'targetVisits',
          label: t`${audience.name} Visits`,
          value: audienceVisits,
          lastPeriod: audienceVisitsLastPeriod,
          percentChange: audienceVisitsPercentChange,
          valueFormat: {},
        }
      : {
          id: 'targetVisits',
          label: t`TAM Visits`,
          value: tamVisits,
          lastPeriod: tamVisitsLastPeriod,
          percentChange: tamVisitsPercentChange,
          valueFormat: {},
        };

    return [
      {
        id: 'visits',
        label: t`Total Visits`,
        value: visits,
        lastPeriod: visitsLastPeriod,
        percentChange: visitsPercentChange,
        valueFormat: {},
      },
      secondItem,
      {
        id: 'engagedCompanies',
        label: audience?.name ? t`${audience.name} Engaged Companies` : t`TAM Engaged Companies`,
        value: engagedCompanies,
        valueLink:
          engagedCompanies != null &&
          engagedCompanies > 0 &&
          ROUTES.webTrafficActivityWithParams({
            'w.audienceIds': audience?.id,
            'w.inTam': audience?.id ? undefined : 'true',
            'w.metrics.visits': '[1,]',
          }),
        lastPeriod: engagedCompaniesLastPeriod,
        percentChange: engagedCompaniesPercentChange,
        valueFormat: {},
      },
      {
        id: 'pipeline',
        label: t`Pipeline Influence`,
        value: pipelineInfluenced,
        lastPeriod: pipelineInfluencedLastPeriod,
        percentChange: pipelineInfluencedPercentChange,
        valueFormat: { isCurrency: true, precision: 0 },
      },
      {
        id: 'closedWon',
        label: t`Closed-Won Influence`,
        value: closedWonInfluenced,
        lastPeriod: closedWonInfluencedLastPeriod,
        percentChange: closedWonInfluencedPercentChange,
        valueFormat: { isCurrency: true, precision: 0 },
      },
    ];
  }, [data, audience]);

  return {
    vendorActivityKpis,
    isLoading,
    error,
  };
};

export const useVendorActivity = (
  tableParams?: SolQueryParamsNew,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = tableParams && startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSolQuery<VendorActivityGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query VendorActivity2(
          $page: Pagination!
          $sort: [SortParamInput]!
          $filter: [FilterParamInput]!
          $startDate: DateTime!
          $endDate: DateTime!
          $audienceId: String
        ) {
          visualization {
            vendorActivity2(
              page: $page
              sort: $sort
              filter: $filter
              startDate: $startDate
              endDate: $endDate
              audienceId: $audienceId
            ) {
              edges {
                node {
                  adClicks
                  adImpressions
                  allVisits: visits
                  allVisitsBest: visitsBest
                  allVisitsWorst: visitsWorst
                  baseAccounts
                  botVisits
                  closedWonFirstTouchAttribution
                  closedWonInfluenced
                  closedWonLinearAttribution
                  countCampaigns
                  engagedCompanies
                  engagedCompaniesBest
                  engagedCompaniesWorst
                  fitScore
                  fitScoreBest
                  fitScoreWorst
                  otherVisits
                  opportunitiesInfluenced
                  opportunityInfluencedBest
                  opportunityInfluencedWorst
                  percentOfEngagedCompanies
                  percentOpportunitiesInfluenced
                  pipelineFirstTouchAttribution
                  pipelineInfluenced
                  pipelineInfluencedBest
                  pipelineInfluencedPerSpend
                  pipelineInfluencedWorst
                  pipelineLastTouchAttribution
                  pipelineLinearAttribution
                  spend
                  spendPerVisit
                  spendPerVisitBest
                  spendPerVisitWorst
                  targetVisits
                  unresolvedVisits
                  vendor {
                    channel {
                      id
                      name
                    }
                    id
                    name
                  }
                  viewThroughVisits
                  visitEfficiency
                }
              }
              totalEdges
            }
          }
        }
      `,
    variables: {
      ...getVariablesFromTableParamsNew(tableParams),
      page: {
        offset: tableParams?.page.offset ?? 0,
        limit: tableParams?.page.limit ?? 100,
      },
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId,
    },
  });

  return {
    vendorActivity: data?.visualization.vendorActivity2.edges.map((edge) => edge.node),
    totalResults: data?.visualization.vendorActivity2.totalEdges,
    isLoading,
    error,
  };
};

export const useOfflineActivity = (
  tableParams?: SolQueryParamsNew,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = tableParams && startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSolQuery<OfflineActivityGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query OfflineActivity(
          $page: Pagination!
          $sort: [SortParamInput]!
          $endDate: DateTime!
          $startDate: DateTime!
          $filter: [FilterParamInput]!
          $audienceId: String
        ) {
          visualization {
            offlineActivity(
              page: $page
              sort: $sort
              endDate: $endDate
              startDate: $startDate
              filter: $filter
              audienceId: $audienceId
            ) {
              campaignType {
                id
                name
                vendor {
                  channel {
                    id
                    name
                    color
                  }
                  id
                  name
                  color
                }
              }
              closedWonFirstTouchAttribution
              closedWonInfluenced
              closedWonLinearAttribution
              engagedCompanies
              otherVisits
              pipelineFirstTouchAttribution
              pipelineInfluenced
              pipelineLastTouchAttribution
              pipelineLinearAttribution
              spend
              targetVisits
              viewThroughVisits
              visits
            }
          }
        }
      `,
    variables: {
      ...getVariablesFromTableParamsNew(tableParams),
      page: {
        offset: 0,
        limit: 100,
      },
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId,
    },
  });

  return {
    offlineActivity: data?.visualization.offlineActivity,
    isLoading,
    error,
  };
};

export const useVendorTrafficOverviewKPIs = (
  vendorId?: string,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = !!(vendorId && startDate?.length && endDate?.length);

  const { data, isLoading, error } = useSolQuery<VendorTrafficOverviewKPIsGQLResponse>({
    query:
      canExecuteQuery &&
      gql`
        query VendorTrafficOverviewKpis(
          $vendorId: String
          $startDate: DateTime
          $endDate: DateTime
          $audienceId: String
        ) {
          visualization {
            trafficOverviewKpis(
              vendorId: $vendorId
              startDate: $startDate
              endDate: $endDate
              audienceId: $audienceId
            ) {
              audienceVisits
              spend
              spendPerAudienceVisit
              spendPerTamVisit
              spendPerVisit
              tamVisits
              visits
            }
          }
        }
      `,
    variables: {
      vendorId,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId,
    },
  });

  return {
    vendorTrafficOverviewKPIs: data?.visualization.trafficOverviewKpis,
    isLoading,
    error,
  };
};

export const useVendorWeeklyVisits = (
  vendorId?: string,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = vendorId && startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSolQuery<VendorWeeklyVisitsGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query VendorWeeklyTraffic(
          $vendorId: String
          $startDate: DateTime
          $endDate: DateTime
          $audienceId: String
        ) {
          visualization {
            weeklyTraffic(
              vendorId: $vendorId
              startDate: $startDate
              endDate: $endDate
              audienceId: $audienceId
            ) {
              data {
                sundayOfWeek
                visits {
                  all
                  audience
                  tam
                }
              }
            }
          }
        }
      `,
    variables: {
      vendorId,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId,
    },
  });

  return {
    vendorWeeklyVisits: data?.visualization.weeklyTraffic[0]?.data,
    isLoading,
    error,
  };
};
