import { ErrorBoundary, wrapCreateBrowserRouterV7 } from '@sentry/react';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router';

import {
  ActivityOverview,
  AttributionOverview,
  AudienceDetail,
  AudienceDetailEdit,
  AudiencesList,
  BenchmarksOverview,
  CampaignDetail,
  CampaignOverview,
  ChannelDetail,
  ChannelMappingRules,
  ChannelOverview,
  ClosedWonOverview,
  CompanySettings,
  ConfigureOrg,
  CreateCustomAudience,
  Dashboard,
  DiscoveryOrgs,
  EventLog,
  ForgotPassword,
  HealthCenter,
  Home,
  ImportSalesforceAudience,
  IntegrationDetail,
  IntegrationsList,
  LoginAs,
  PipelineOverview,
  PixelCompare,
  PixelCreate,
  PixelDetail,
  PixelEdit,
  PixelsOverview,
  Profile,
  SignIn,
  SignUp,
  TenantMagicLinkSignIn,
  TenantSignIn,
  UserDetail,
  UserInvite,
  UsersList,
  VendorDetail,
  VendorOverview,
  VendorSpend,
  VerifyIntegration,
  WebTraffic,
} from '@/app';
import Error500 from '@/components/errors/Error500';
import { FEATURE_FLAG } from '@/constants/featureFlags';

import AuthenticatedRoute from './AuthenticatedRoute';
import PaidRoute from './PaidRoute';
import UserRoleRoute from './UserRoute';
import { ROUTES } from './routes';

const sentryCreateBrowserRouter = wrapCreateBrowserRouterV7(createBrowserRouter);

const getRouter = (featureFlags: LDFlagSet) => {
  return sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <ErrorBoundary fallback={<Error500 />}>
            <Outlet />
          </ErrorBoundary>
        }
      >
        <Route path="*" element={<Navigate to={ROUTES.app.path} replace />} />

        {/* SIGN-IN */}
        <Route path={ROUTES.signIn.path} element={<SignIn />} />
        <Route path={ROUTES.signInDiscovery.path} element={<DiscoveryOrgs />} />

        {
          //===================================================================================
          // DO NOT ENABLE tenant auth workflow in production until we are ready to support it.
          //===================================================================================
          import.meta.env.VITE_ENVIRONMENT !== 'production' && (
            <>
              <Route path={ROUTES.tenantSignIn.path} element={<TenantSignIn />} />
              <Route path={ROUTES.tenantSignInMagicLink.path} element={<TenantMagicLinkSignIn />} />
              <Route path={ROUTES.tenantForgotPassword.path} element={<ForgotPassword />} />
            </>
          )
        }

        {/* SIGN UP */}
        <Route path={ROUTES.signUp.path} element={<SignUp />} />
        <Route path={ROUTES.configureOrg.path} element={<ConfigureOrg />} />

        <Route
          path={ROUTES.app.path}
          element={
            <AuthenticatedRoute>
              <Home />
            </AuthenticatedRoute>
          }
        >
          <Route path={ROUTES.dashboard.path} element={<Dashboard />} />

          <Route path={ROUTES.profile.path} element={<Profile />} />
          <Route element={<UserRoleRoute role="c99Superuser" />}>
            <Route path={ROUTES.loginAs.path} element={<LoginAs />} />
          </Route>

          <Route path={ROUTES.webTraffic.path} element={<WebTraffic />} />
          <Route path={ROUTES.webTrafficBenchmarks.path} element={<BenchmarksOverview />} />
          <Route path={ROUTES.webTrafficActivity.path} element={<ActivityOverview />} />
          <Route path={ROUTES.healthCenter.path} element={<HealthCenter />} />

          <Route element={<UserRoleRoute role="admin" />}>
            {/* SETTINGS */}
            <Route path={ROUTES.users.path} element={<UsersList />} />
            <Route path={ROUTES.userInvite.path} element={<UserInvite />} />
            <Route path={ROUTES.userDetail.path} element={<UserDetail />} />
            <Route path={ROUTES.companySettings.path} element={<CompanySettings />} />
            <Route path={ROUTES.eventLog.path} element={<EventLog />} />
          </Route>

          <Route element={<PaidRoute />}>
            {/* CHANNELS */}
            <Route path={ROUTES.channels.path} element={<ChannelOverview />} />
            <Route path={ROUTES.channelDetail.path} element={<ChannelDetail />} />

            {/* VENDORS */}
            <Route path={ROUTES.vendors.path} element={<VendorOverview />} />
            <Route path={ROUTES.vendorDetail.path} element={<VendorDetail />} />

            {/* CAMPAIGNS */}
            <Route path={ROUTES.campaigns.path} element={<CampaignOverview />} />
            <Route path={ROUTES.campaignDetail.path} element={<CampaignDetail />} />

            {/* PIXELS */}
            <Route path={ROUTES.pixels.path} element={<PixelsOverview />} />
            <Route path={ROUTES.pixelCompare.path} element={<PixelCompare />} />
            <Route path={ROUTES.pixelDetail.path} element={<PixelDetail />} />
            <Route element={<UserRoleRoute role="admin" />}>
              <Route path={ROUTES.pixelCreate.path} element={<PixelCreate />} />
              <Route path={ROUTES.pixelEdit.path} element={<PixelEdit />} />
            </Route>

            <Route path={ROUTES.closedWon.path} element={<ClosedWonOverview />}>
              <Route path=":opportunity" element={null} />
            </Route>
            {featureFlags[FEATURE_FLAG.attribution] ? (
              <Route path={ROUTES.attribution.path} element={<AttributionOverview />} />
            ) : (
              <Route path={ROUTES.pipeline.path} element={<PipelineOverview />} />
            )}

            {/* AUDIENCES */}
            <Route path={ROUTES.audiences.path} element={<AudiencesList />} />
            <Route path={ROUTES.audienceImport.path} element={<ImportSalesforceAudience />} />
            <Route path={ROUTES.audienceDetail.path} element={<AudienceDetail />} />
            <Route element={<UserRoleRoute role="admin" />}>
              <Route path={ROUTES.audienceCreate.path} element={<CreateCustomAudience />} />
              <Route path={ROUTES.audienceDetailEdit.path} element={<AudienceDetailEdit />} />
            </Route>

            <Route element={<UserRoleRoute role="admin" />}>
              <Route path={ROUTES.configureVendors.path} element={<VendorSpend />} />
              <Route path={ROUTES.configureChannels.path} element={<ChannelMappingRules />} />

              {/* INTEGRATIONS */}
              <Route path={ROUTES.integrations.path} element={<IntegrationsList />} />
              <Route path={ROUTES.integrationDetail.path} element={<IntegrationDetail />} />
              <Route path={ROUTES.verifyIntegration.path} element={<VerifyIntegration />}>
                <Route path=":provider" element={null} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>,
    ),
  );
};

export default getRouter;
